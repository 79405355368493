import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ListBox } from 'primereact/listbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import apiCalls from '../../../config/apiCalls';
import { faTrash, faEdit, faInfoCircle ,faUser} from '@fortawesome/free-solid-svg-icons';
import DeleteRowModal from '../../Cruds/CommonModals/DeleteRowModal';
import { Tooltip } from 'reactstrap';
import TreatmentPlanDialog from '../../Cruds/CommonModals/TreatmentPlanModal';
import { Dialog } from 'primereact/dialog';
import Charts from '../../Cruds/Charts/components/Charts';
import config from '../../../config/config';
import RefferealModal from '../../Cruds/CommonModals/RefferealModal';
import AutoNoteSelectModal from './AutoNoteForms/AutoNoteSelectModal';
import axios from 'axios';
import Signature from './Signature';

const DragAndDropTreatment = (props) => {
  const { children } = props;
  let { i, item,control, Controller, getValues, setValue, errors, selectedData, setCustomFieldValidation, loginRole, heading, watch, reset, submit, handleSubmit } = props;
  const [dataTableData, setDataTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [originalData, setOriginalData] = useState([]);
  const [rowPriorities, setRowPriorities] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [originalDataTable, setOriginalDataTable] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [noteDialogVisible, setNoteDialogVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const [isMultiDelete, setIsMultiDelete] = useState(false);
  const [isOpenReffereal, setIsOpenReffereal] = useState(false);
  const [IsOpenAutoNotes, setIsOpenAutoNotes] = useState();
  const [isOpenSignDialog, setIsOpenSignDialog] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    getDataFromServer();
  }, [props.data]);

  const filterData = (data, predicate) => {
    return data.filter(predicate);
  };

  useEffect(() => {
    dataTableDataChange(dataTableData);
  }, [dataTableData]);

  function dataTableDataChange(dataTableData, saveDataToServer) {
    let data = dataTableData.reduce((acc, curr) => acc + (curr.isSubtotal ? 0 : parseFloat(curr.fee || 0)), 0);
    setTotalFee(data);
    const predicate = item => !item.isSubtotal;
    const filteredDataTableData = filterData(dataTableData, predicate);
    const filteredOriginalDataTable = filterData(originalDataTable, predicate);
    if (props.setValue) {
      props.setValue('procedureSteps', filteredOriginalDataTable);
      props.setValue('teethProcedureSteps', filteredDataTableData);
    }
    if (saveDataToServer === true && props.data?._id) {
      const payload =getValues();
      fetchMethodRequest('PUT',`${apiCalls.treatmentPlan}/${props.data._id}`,{...props.data,...payload}).then(res=>{
        if(res.respCode){
          props.setValue('treatmentPlanHeading',res.details);
        }
      })
      // handleSubmit(submit)();
    }
  }


  /**
   * 
   * @param {Array} procedures 
   * @param {Array} teeths 
   * @returns Array
   */
  function addTeethToProceduresSteps(procedures, teeths) {

    teeths.map(teeth => {
      const index = procedures.findIndex(pro => pro.code == teeth.code);
      if (index >= 0) {
        let teethArr = procedures[index]?.teethNumber ? procedures[index]?.teethNumber : [];
        teeth.teethNumber = teeth.teethNumber ? teeth.teethNumber : null;
        if (!teethArr.includes(teeth.teethNumber)) {
          teethArr.push(teeth.teethNumber);
        }
        procedures[index].teethNumber = teethArr;
      } else {        
        procedures.push({...teeth,teethNumber:[teeth.teethNumber]});
      }
      return teeth;
    })
    return procedures;
  }

  const customizeData = (initialData) => {
    const dataWithoutSubtotals = initialData.filter(item => !item.isSubtotal);
    let groupedData = dataWithoutSubtotals.reduce((acc, item) => {
      const priority = item.priority || 'default';
      if (!acc[priority]) {
        acc[priority] = { items: [], allHavePriority: true };
      }
      acc[priority].items.push(item);
      if (!item.priority) {
        acc[priority].allHavePriority = false;
      }
      return acc;
    }, {});

    const result = [];
    const priorities = Object.keys(groupedData).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

    let hasPriorities = priorities.some(priority => priority !== 'default');

    priorities.forEach(priority => {
      const group = groupedData[priority];
      const items = group.items;
      result.push(...items);

      if (hasPriorities && group.allHavePriority) {
        const subtotal = items.reduce((sum, item) => sum + parseFloat(item.fee || 0), 0);
        result.push({
          isSubtotal: true,
          _id: `subtotal-${priority}-${Date.now()}`,
          description: `Subtotal`,
          subtotal: subtotal,
          priority: priority
        });
      }
    });

    return result;
  };


  const getDataFromServer = () => {
    if (props.data && Array.isArray(props.data.teethProcedureSteps)) {
      const initialData = (props.data && props.data.teethProcedureSteps && props.data.teethProcedureSteps.length > 0) ? props.data.teethProcedureSteps : [];
      const customizedData = customizeData(initialData);
      setDataTableData(customizedData);
      const originalData = (props.data && props.data.procedureSteps && props.data.procedureSteps.length > 0) ? props.data.procedureSteps : [];
      setOriginalDataTable(originalData);
    } else {
      setDataTableData([]);
      setOriginalData([]);
      setSelectedRows([]);
      setRowPriorities({});
      setOriginalDataTable([]);
    }
  }
  const handleCheckboxChange = (dataItem) => {
    const rowKey = `${dataItem._id}-${dataItem.teethNumber}`;
    setSelectedRows(prevSelectedRows => {
      const isSelected = prevSelectedRows.includes(rowKey);
      if (isSelected) {
        return prevSelectedRows.filter(id => id !== rowKey);
      } else {
        return [...prevSelectedRows, rowKey];
      }
    });
  };
  


  const handlePriorityChange = (newPriority) => {
    // if (newPriority === null) {
    //   const resetData = dataTableData.map(item => {
    //     const newItem = { ...item };
    //     delete newItem.priority;
    //     return newItem;
    //   }).filter(item => !item.isSubtotal);

    //   setDataTableData(resetData);
    //   setSelectedRows([]);
    //   setRowPriorities({});

    //   const total = resetData.reduce((acc, curr) => acc + parseFloat(curr.fee || 0), 0);
    //   setTotalFee(total);
    // } else {
      if (selectedRows.length === 0) {
        return;
      }
      updatePriorities(newPriority, selectedRows);
    // }
  };


  const updatePriorities = (newPriority, selectedRowIds) => {
    const updatedRowPriorities = { ...rowPriorities };
    selectedRowIds.forEach(rowId => {
      updatedRowPriorities[rowId] = newPriority;
    });

    setRowPriorities(updatedRowPriorities);

    groupAndSortData(updatedRowPriorities);
  };

  const groupAndSortData = (updatedRowPriorities) => {
    const nonSubtotalData = dataTableData.filter(row => !row.isSubtotal);

    const updatedData = nonSubtotalData.map(row => {
      const rowKey = `${row._id}-${row.teethNumber}`;
      if(updatedRowPriorities[rowKey] || updatedRowPriorities[rowKey] === null){
        row.priority = updatedRowPriorities[rowKey];
      }
      return row;
    });

    let groupedData = updatedData.reduce((acc, row) => {
      const priority = row.priority || 'default';
      (acc[priority] = acc[priority] || []).push(row);
      return acc;
    }, {});

    const sortedGroupKeys = Object.keys(groupedData).sort((a, b) => parseInt(a) - parseInt(b));

    let finalData = [];
    sortedGroupKeys.forEach(priority => {
      const groupRows = groupedData[priority];
      if (priority !== 'default') {
        const groupSubtotal = groupRows.reduce((sum, row) => sum + parseFloat(row.fee || 0), 0);

        finalData.push(
          ...groupRows,
          {
            isSubtotal: true,
            _id: `subtotal-${priority}`,
            description: `Subtotal`,
            subtotal: groupSubtotal,
          }
        );
      } else {
        finalData.push(...groupRows);
      }
    });

    setDataTableData(finalData);
    setOriginalData(originalDataTable);
    setSelectedRows([]);
  };

  const openDeleteModal = (id) => {
    setRowToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDelete = () => {
    if (rowToDelete !== null) {
      const [deleteId, deleteTeethNumber] = rowToDelete.split('-');
      const deleteTeethNumberInt = parseInt(deleteTeethNumber) ? parseInt(deleteTeethNumber) : null;
      let filteredData = dataTableData.filter(item => {
        return !(item._id === deleteId && (item.teethNumber === deleteTeethNumberInt || deleteTeethNumberInt === null)) && !item.isSubtotal;
      });
      const updatedOriginalData = originalDataTable.reduce((acc, data) => {
        if (data._id === deleteId) {
          const filteredTeethNumbers = data.teethNumber ? data.teethNumber.filter(num => num !== deleteTeethNumberInt) : '';
          if (filteredTeethNumbers.length > 0) {
            acc.push({ ...data, teethNumber: filteredTeethNumbers });
          }
        } else {
          acc.push(data);
        }
        return acc;
      }, []);

      const treatmentPlanId = props.getValues('treatmentPlanHeading');
      const payload = {
        patientId: props.getValues('patientId'),
        teethProcedureSteps: filteredData,
        procedureSteps: updatedOriginalData,
        hospitalId: treatmentPlanId.hospitalId,
        heading: treatmentPlanId.heading,
        txPlanId: treatmentPlanId.txPlanId,
        note: props.getValues('note'),
        _id: treatmentPlanId._id
      };

      const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId._id}`;

      fetchMethodRequest('PUT', requestUrl, payload)
        .then(response => {
          if (response.respCode) {
            showToasterMessage('Deleted successfully', 'success');
            props.setValue('treatmentPlanHeading', response.details);
            setDataTableData(customizeData(response.details.teethProcedureSteps));
            setOriginalDataTable(customizeData(response.details.procedureSteps))
            setSelectedRows([]);
          } else {
            showToasterMessage('Error updating treatment plan', 'error');
          }
        })
        .catch(error => {
          showToasterMessage('Error updating treatment plan', 'danger');
        });

      setIsDeleteModalOpen(false);
    }
  };
  
  const toggleTooltip = (id) => {
    setTooltipOpen(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleEmailTreatment = () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    let payload = {
      patientId: PatientId,
      treatmentPlanId: treatmentPlanId
    };
    const requestUrl = `${apiCalls.treatmentPlan}/treatmentConfirmationEmail/${treatmentPlanId}`;
    fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {
        if (response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setSelectedRows([]);
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.error('Error canceling treatment:', error);
        showToasterMessage(response.errorMessage, 'danger');
      })
  }

  const handlePrintTreatment = () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    let print="true";
    let payload = {
      patientId: PatientId,
      treatmentPlanId: treatmentPlanId,
      print:print,
    };
    const requestUrl = `${apiCalls.treatmentPlan}/generatePdfs/${treatmentPlanId}`;
    fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setSelectedRows([]);
          if (response.pdfPath) {
            const downloadUrl = config.previousImageUrl + response.pdfPath;
            window.open(downloadUrl, '_blank');
          }
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.error('Error canceling treatment:', error);
        showToasterMessage(response.errorMessage, 'danger');
      })
  }

  const rowClassName = (item) => {
    switch (item.treatmentStatus) {
      case 'Complete':
        return "text-complete";
      default:
        return "row-default";
    }
  }

  const openSelectedNotes = (data,type) => {
    if(data){
      setRowData(data);
    }
    setIsOpenAutoNotes(type);
    setCustomFieldValidation({ save: false, });
  }

  const getDateToDisplay = (data) => {
    if (data.teethUpdatedDate) {
      return new Date(data.teethUpdatedDate).toLocaleDateString('en-US');
    } else if (data.teethCreatedDate) {
      return new Date(data.teethCreatedDate).toLocaleDateString('en-US');
    }
    return '';  // Return empty if neither date is available
  };
  
  
  const renderTableData = () => (
    <tbody>
      {dataTableData.map((data, index) => {
      const uniqueKey = `${data._id}-${data.teethNumber}`;
      const formattedDate = getDateToDisplay(data);
      const className = rowClassName(data);
        if (data.isSubtotal) {
          return (
            <tr key={`subtotal-${index}`} className="text-center" style={{ fontWeight: 'bold', borderBottom: '2px solid black' }}>
              <td colSpan="6">{data.description}</td>
              <td colSpan="2" style={{textAlign : 'left'}}>${data.subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          );
        } else if (data.isSeparator) {
          return (
            <tr key={`separator-${uniqueKey}`} className="separator-row text-center">
              <td colSpan="8" style={{ borderTop: '2px solid #000' }}></td>
            </tr>
          );
        } else {

          return (
            <tr key={uniqueKey} className={className}>
              <td style={{width:"50px"}}>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(uniqueKey)}
                  onChange={() => handleCheckboxChange(data)}
                  className='treatmentplan-checkbox'
                  disabled={data.treatmentStatus === "Complete"}
                />
              </td>
              <td style={{width:"80px",textAlign:'center'}}>{formattedDate}</td>
              <td style={{width:"40px",textAlign:'center'}}>{data.teethNumber}</td>
              <td style={{width:"40px",textAlign:'center'}}>{data.priority || ''}</td>
              <td style={{width:"60px",textAlign:'center'}}>{data.code}</td>
              <td title={data.description} className='procedure_table_column_textOverflow' style={{  textTransform: 'capitalize' , maxWidth:"400px"}}>
                {data.description}
                {data.note && (
                  <div>
                    <span className='procedure_table_column_textOverflow' style={{ color: '#888',fontSize: '0.8em', maxWidth:'370px'}}>
                      {data.note.substring(0, 50)}...
                    </span>
                    <FontAwesomeIcon icon={faInfoCircle} onClick={() => handleNoteClick(data.note)} style={{ marginLeft: '5px' }} />
                  </div>
                )}
              </td>
              <td style={{width:"80px",textAlign:'center'}}>${data.fee ? data.fee.toFixed(2).toLocaleString() : ''}</td>
              <td style={{minWidth:"140px"}} className='d-flex d-flex justify-content-center'>
                <button type='button' style={{ border: 'none', background: 'transparent' }}>
                  <FontAwesomeIcon title='Edit' icon={faEdit} onClick={() => handleEditClick(data)} />
                </button>
                {data.treatmentStatus !== "Complete" && <button type='button' style={{ border: 'none', background: 'transparent' }}>
                  <FontAwesomeIcon title='Delete' icon={faTrash} style={{ color: 'red' }} onClick={() => openDeleteModal(uniqueKey)} />
                </button>}
                <button type='button' style={{ border: 'none', background: 'transparent' }}>
                  <FontAwesomeIcon title='Auto Notes' icon={'pencil-alt'} onClick={ ()=> openSelectedNotes(data,'add')} />
                </button>
                <button type='button' style={{ border: 'none', background: 'transparent' }} disabled={!data.autoNote}>
                  <FontAwesomeIcon title='View Auto Notes' icon={'eye'} onClick={ ()=> openSelectedNotes(data, 'view')} />
                </button>
              </td>
              {/* <td>{data.notes ? data.notes : "-"}</td> */}
              {/* <td style={{maxWidth:'100px'}}>
                {data.autoNote ? (
                    data.autoNote.split('.').map((note, index) => (
                      <div title={note} className='procedure_table_column_textOverflow' style={{maxWidth:""}} key={index}>
                        {note}
                      </div>
                    ))
                  ) : (
                    "-"
                  )}
              </td> */}

            </tr>
          );
        }
      })}
    </tbody>
  );


const handleActivatePlan = () => {
  const PatientId = props.getValues('patientId');
  const treatmentPlanId = props.getValues('treatmentPlanHeading');
  const hospitalId = treatmentPlanId.treatmentPlanId;
  const txPlanId = treatmentPlanId.txPlanId;
  const note = props.getValues('note');

  let heading = treatmentPlanId.heading;

  // Step to create an aggregated object with combined teethNumber for the same _id, excluding empty arrays
  const procedureStepsMap = selectedRows.reduce((acc, rowId) => {
      const [id, teethNumber] = rowId.split('-');
      const parsedTeethNumber = teethNumber !== "null" && teethNumber !== "undefined" ? parseInt(teethNumber, 10) : null;

      const step = dataTableData.find(row => row._id === id);
      if (step) {
          if (!acc[step._id]) {
              acc[step._id] = { ...step };
              if (parsedTeethNumber !== null) {
                  acc[step._id].teethNumber = [parsedTeethNumber];
              }
          } else if (parsedTeethNumber !== null && !acc[step._id].teethNumber.includes(parsedTeethNumber)) {
              acc[step._id].teethNumber.push(parsedTeethNumber);
          }
      }
      return acc;
  }, {});

  // Filtering steps to remove the teethNumber attribute if it's an empty array
  const procedureSteps = Object.values(procedureStepsMap).map(step => {
      if (step.teethNumber && step.teethNumber.length === 0) {
          const { teethNumber, ...restStep } = step;
          return restStep;
      }
      return step;
  });

  const regex = /^(Active [^\(\)]+)(?: \((\d+)\))?$/;
  const match = heading.match(regex);
  if (match) {
      const base = match[1];
      const number = match[2];
      if (number) {
          heading = `${base} (${parseInt(number, 10) + 1})`;
      } else {
          heading = `${base} (1)`;
      }
  } else {
      heading = `Active ${treatmentPlanId.heading}`;
  }

  let payload = {
      patientId: PatientId,
      note: note,
      hospitalId: hospitalId,
      heading: heading,
      txPlanId: txPlanId,
      procedureSteps: procedureSteps
  };

  const requestUrl = apiCalls.treatmentPlan;
  fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {
          if (response.respMessage) {
              showToasterMessage(response.respMessage, 'success');
              setValue('treatmentPlanHeading', response.details);
              setDataTableData(response.details.teethProcedureSteps);
              setSelectedRows([]);
          } else {
              showToasterMessage(response.errorMessage, 'error');
          }
      })
      .catch(error => {
          console.error('Error activating plan:', error);
          showToasterMessage(response.errorMessage, 'danger');
      })
      .finally(() => {
          setSelectedRows([]);
      });
};

  const handleEditClick = (item) => {
    setSelectedItemForEdit({...item,patientId: props.getValues('patientId')});
    setDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setIsOpenReffereal(false)
  };

  const handleSaveChanges = (data) => {
    const updatedProcedureSteps = originalDataTable;
    let duplicateData = false;
    const updatedData = dataTableData.map(item => {
      if (item._id === selectedItemForEdit._id && item.teethNumber === selectedItemForEdit.teethNumber) {
        const procedureIndex = updatedProcedureSteps.findIndex(procedure=>procedure._id===selectedItemForEdit._id);
        const procedure = updatedProcedureSteps[procedureIndex];
        if(procedure?.teethNumber?.length){
          if (procedure.teethNumber.includes(data.teethNumber) && data.teethNumber !== selectedItemForEdit.teethNumber) {
            duplicateData = true;
          } else {
            const teethIndex = procedure.teethNumber.findIndex(teeth=>teeth===selectedItemForEdit.teethNumber);
            procedure.teethNumber[teethIndex] = data.teethNumber? data.teethNumber : null;
            updatedProcedureSteps[procedureIndex] = procedure;
          }
        }
        return {
          ...item,
          note: data.note,
          priority: data.priority,
          teethNumber: data.teethNumber,
          signature : data.signature,
          treatmentStatus: data.treatmentStatus,
          provider: data.provider,
          referral: data.referral,
          autoNote:data.autoNote,
          completedDate: Date.now(),
          teethUpdatedDate: new Date(),
        };
      }
      return item;
    });
    if (duplicateData) {
      showToasterMessage('Procedure with this teeth is already exists','warning');
      return;
    }
    setDataTableData(updatedData);
    const filteredProcedureSteps = updatedData.filter(procedure => !procedure.isSubtotal);
    updateTreatmentStatus(filteredProcedureSteps,updatedProcedureSteps);
  };

  const updateTreatmentStatus = async (updatedDataTableData,updatedProcedureSteps) => {
    updatedProcedureSteps = updatedProcedureSteps ? updatedProcedureSteps : originalDataTable;
    const treatmentPlanId = await props.getValues('treatmentPlanHeading');
    updatedProcedureSteps =  await addTeethToProceduresSteps(updatedProcedureSteps, updatedDataTableData.filter(procedure=>!procedure.isSubtotal));
    const singleProcedureEdit = "true";
    const payload = {
      patientId: props.getValues('patientId'),
        teethProcedureSteps: updatedDataTableData,
        procedureSteps: updatedProcedureSteps,
        hospitalId: treatmentPlanId.hospitalId,
        heading: treatmentPlanId.heading,
        txPlanId: treatmentPlanId.txPlanId,
        note: props.getValues('note'),
        _id: treatmentPlanId._id,
        singleProcedureEdit:singleProcedureEdit
      };
      const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId._id}`;
      fetchMethodRequest('PUT', requestUrl, payload)
        .then(response => {
          if (response.respCode) {
            showToasterMessage('Updated successfully', 'success');
            props.setValue('treatmentPlanHeading',response.details);
            setDataTableData(customizeData(response.details.teethProcedureSteps));
            setOriginalDataTable(customizeData(response.details.procedureSteps))
            setSelectedRows([]);
            handleCloseDialog();
        } else {
          showToasterMessage('Error updating treatment plan', 'error');
        }
      })
      .catch(error => {
        console.error('Error updating treatment plan:', error);
        showToasterMessage('Error updating treatment plan', 'danger');
      });
  }
  //notemodal
  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setNoteDialogVisible(true);
  };
  const NoteModal = ({ visible, onHide, note }) => (
    <Dialog header="Note Details" visible={visible} onHide={onHide} style={{ width: '50vw' }}>
      <p style={{paddingTop:'15px'}}>{note}</p>
    </Dialog>
  );

  
  const setDataTableDataFun = async (arr,render) => {
    setDataTableData(arr);
    if(render){
      const orgData = await addTeethToProceduresSteps(originalDataTable, arr);
      setOriginalDataTable(orgData);
      setTimeout(() => {
        dataTableDataChange(arr, true);
      }, 100);
    }

  }

  const getAutonotesData = (data) =>{
    let arr =dataTableData.map(item=>{
      if(item._id === rowData._id && item.teethNumber===rowData.teethNumber){
        item.autoNote = data
      }
      return item;
    })
   
    setDataTableDataFun(arr,true);


  }

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelectedRows = dataTableData.filter(item => !item.isSubtotal && item.treatmentStatus !== "Complete").map(item => `${item._id}-${item.teethNumber}`);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };
  //multidelete
  const openMultiDeleteModal = () => {
    setIsMultiDelete(true);
    setIsDeleteModalOpen(true);
  };

  const handleMultiDelete = () => {
    if (selectedRows.length === 0) {
      showToasterMessage('No rows selected for deletion', 'error');
      return;
    }

    let filteredData = dataTableData.filter(item =>
      !selectedRows.includes(`${item._id}-${item.teethNumber}`) && !item.isSubtotal
    );


    const updatedOriginalData = originalDataTable.reduce((acc, data) => {
      if (data.teethNumber) {
        const teethNumbers = data.teethNumber.filter(teethNumber =>
          !selectedRows.includes(`${data._id}-${teethNumber}`)
        );
        if (teethNumbers.length > 0) {
          acc.push({ ...data, teethNumber: teethNumbers });
        }
      } else {
        const nullKey = `${data._id}-null`;
        const undefinedKey = `${data._id}-undefined`;
        if (!selectedRows.includes(nullKey) && !selectedRows.includes(undefinedKey)) {
          acc.push(data);
        }
      }
      return acc;
    }, []);

    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const payload = {
      patientId: props.getValues('patientId'),
      teethProcedureSteps: filteredData,
      procedureSteps: updatedOriginalData,
      hospitalId: treatmentPlanId.hospitalId,
      heading: treatmentPlanId.heading,
      txPlanId: treatmentPlanId.txPlanId,
      note: props.getValues('note'),
      _id: treatmentPlanId._id
    };

    const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId._id}`;

    fetchMethodRequest('PUT', requestUrl, payload)
      .then(response => {
        if (response.respCode) {
          showToasterMessage('Deleted successfully', 'success');
          props.setValue('treatmentPlanHeading', response.details);
          setDataTableData(customizeData(response.details.teethProcedureSteps));
          setOriginalDataTable(customizeData(response.details.procedureSteps));
          setSelectedRows([]);
        } else {
          showToasterMessage('Error updating treatment plan', 'error');
        }
      })
      .catch(error => {
        console.error('Error updating treatment plan:', error);
        showToasterMessage('Error updating treatment plan', 'danger');
      });
    setIsDeleteModalOpen(false);
  }; 
  
  const fetchTreatmentPlanDetails = async (treatmentPlanId) => {
    try {
      const requestUrl = `${apiCalls.treatmentPlan}/${treatmentPlanId}`;
      const response = await fetchMethodRequest('GET', requestUrl);
      
      if (response.details) {
        props.setValue('treatmentPlanHeading', response.details);
        setDataTableData(customizeData(response.details.teethProcedureSteps));
        setOriginalDataTable(customizeData(response.details.procedureSteps));
        setSelectedRows([]);
        handleCloseDialog();
        showToasterMessage('Treatment completed successfully', 'success');
      } else {
        showToasterMessage('Error fetching treatment plan details', 'error');
      }
    } catch (error) {
      console.error('Error fetching treatment plan details:', error);
      showToasterMessage('Error fetching treatment plan details', 'danger');
    }
  };

  const handleCompleteTreatment = () => {
    const selectedProcedures = selectedRows.map(rowId => {
      const [id, teethNumber] = rowId.split('-');
     
      if (teethNumber === "undefined" || teethNumber === undefined || teethNumber === "null" ) {
        // Handle cases where teethNumber is not defined
        return dataTableData.find(row => row._id === id && row.teethNumber == null);
      } else {
        const parsedNumber = parseInt(teethNumber, 10);
        if (!isNaN(parsedNumber)) {
          // Handle cases where teethNumber is a valid number
          return dataTableData.find(row => row._id === id && row.teethNumber === parsedNumber);
        }
      }
    }).filter(procedure => procedure !== undefined);


    const patientTreatment = selectedProcedures
      .filter(procedure => !procedure.treatmentStatus || procedure.treatmentStatus !== 'Complete')
    const updatedDataTableData = dataTableData.map(data => {
      const selectedProcedure = selectedProcedures.find(proc => proc._id === data._id && proc.teethNumber === data.teethNumber);
      if (selectedProcedure) {
        return { ...data, treatmentStatus: 'Complete', completedDate: new Date() };
      }
      return data;
    });

    const updatedOriginalData = originalDataTable.map(data => {
      const selectedProcedure = selectedProcedures.find(proc => proc._id === data._id && proc.teethNumber === data.teethNumber);
      if (selectedProcedure) {
        return { ...data, treatmentStatus: 'Complete' };
      }
      return data;
    });
     
    const filteredProcedureSteps = updatedDataTableData.filter(procedure => !procedure.isSubtotal);
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;


    const payload = {
      patientTreatment: patientTreatment,
      patientId: props.getValues('patientId')
    };

    fetchMethodRequest('POST', apiCalls.accounts, payload)
      .then(response => {
        if (response.respMessage) {
          // updateTreatmentStatus(filteredProcedureSteps);
          // setDataTableData(updatedDataTableData);
          // setOriginalDataTable(updatedOriginalData);
          fetchTreatmentPlanDetails(treatmentPlanId);
          showToasterMessage('Treatment completed successfully', 'success');
        } else {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(error => {
        console.error('Error completing treatment:', error);
        showToasterMessage('Error completing treatment', 'danger');
      })
      .finally(() => {
        setSelectedRows([]);
      });
  };

  const handleSignTreatment = async (rowData) => {
    const patientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    const requestUrl = `${config.apiUrl}/${apiCalls.signTP}/${treatmentPlanId}?type=signTP`;
    try {
      const response = await axios.get(requestUrl, {
        responseType: 'blob'
      });
      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfBlobUrl);
        setRowData(rowData);
        setIsOpenSignDialog(true);
        getPatientSignature();
        showToasterMessage('PDF generated successfully', 'success');
      } else {
        showToasterMessage('Failed to generate PDF', 'error');
      }
    } catch (error) {
      console.error('Error signing treatment:', error);
      showToasterMessage(error.response?.data?.errorMessage || 'An error occurred', 'danger');
    }
  };

  const getPatientSignature =  () => {
    const PatientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading')._id;
    const requestUrl = `${apiCalls.treatmentPlan}/PatientSign/${treatmentPlanId}`;
    fetchMethodRequest('GET', requestUrl)
    .then(response => {
      // Check if PatientSign exists and is not just an empty string
      if (response && response.PatientSign && response.PatientSign.trim() !== "") {
          setValue("patientSignature", response.PatientSign);
      } else {
          setValue("patientSignature", "");  // Optionally clear the previous value
      }
    })
    .catch(error => {
      console.error('Error fetching patient signature:', error);
    });
  }
  
  const closeAutoNotes = () =>{
    setCustomFieldValidation({ save: true, });
    setIsOpenAutoNotes();
    
  }

  const getSignature = (index, item) => {

    return <Controller
      name={item.name ? item.name : null}
      control={control}
      render={({ field, fieldState }) => (
        <Signature
          item={item}
          field={field}
          fieldState={fieldState}
          errors={errors}
        />)}
    />
  }

  const getSignDialog = () => {
    return (
      <Dialog
        header="Sign Treatment Plan"
        visible={isOpenSignDialog}
        headerClassName="modal-header"
        draggable={false}
        style={{ width: '60vw', height: '90vh' }}
        onHide={() => setIsOpenSignDialog(false)}
        modal
      >
        <div className="row" style={{ height: "100%" }}>
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '90%', border: 'none' }}
            title="PDF Viewer"
          />
          <div className="container">
            {getSignature(1,{ name: 'patientSignature', label: 'Patient Signature', id: 'signature', required: true })}
            <Button color="primary" onClick={handleSaveSignature} style={{ marginTop: '10px' }}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleSaveSignature = async () => {
    let data =getValues();
    const patientId = props.getValues('patientId');
    const treatmentPlanId = props.getValues('treatmentPlanHeading');
    const signature = getValues('patientSignature');

    let payload = {
      patientId: patientId,
      procedureSteps:originalDataTable,
      teethProcedureSteps:dataTableData,
      signatureTP: signature,
      note: props.getValues('note'),
      _id:treatmentPlanId._id,
      heading: treatmentPlanId.heading,
      txPlanId: treatmentPlanId.txPlanId,
    };
    const requestUrl = `${apiCalls.treatmentPlan}/UpdateSign`;

    try {
      const response = await fetchMethodRequest('POST', requestUrl, payload);
      if (response.respCode) {
        showToasterMessage('Signature saved successfully', 'success');
        setIsOpenSignDialog(false);
      } else {
        showToasterMessage('Failed to saved signature', 'error');
      }
    } catch (error) {
      console.error('Error saving signature:', error);
      showToasterMessage('An error occurred while saving the signature', 'danger');
    }
  };

  const getAutoNoteDialog = () => {
    if (IsOpenAutoNotes === 'add') {
      return <AutoNoteSelectModal
        IsOpenAutoNotes={IsOpenAutoNotes}
        setIsOpenAutoNotes={setIsOpenAutoNotes}
        closeSelectedAutoNotesModal={closeAutoNotes}
        getAutonotesData={getAutonotesData}
      />;
    } else if (IsOpenAutoNotes === 'view' && rowData.autoNote) {
      console.log('rowData', rowData);
      return <Dialog header='View Auto Note' visible={true} onHide={closeAutoNotes} draggable={false} style={{ minWidth: '30vw' }}>
        <br/>
        {rowData.autoNote.split('\n').map((note, index) => (
          <div title={note} className='procedure_table_column_textOverflow' style={{ maxWidth: "" }} key={index}>
            {note}
          </div>
        ))}
      </Dialog>
    }
  }

  return (
    <div>
      
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className='col-4'>{ children}</div>
        {dataTableData.length > 0 && (
          <div className='col-8 mt-4 px-2'>
            <Button onClick={handleActivatePlan} color="primary" disabled={selectedRows && selectedRows.length === 0}>
              Make Active Plan
            </Button>
            <Button onClick={() => handleSignTreatment(rowData)} color="primary">
              Sign
            </Button>
            <Button onClick={handlePrintTreatment} color="primary">
              Print
            </Button>
            <Button onClick={handleEmailTreatment} color="primary">
              Email
            </Button>
            {/* <Button color="primary" type="submit">
              Update
            </Button> */}
            <Button onClick={openMultiDeleteModal} color="primary" disabled={selectedRows && selectedRows.length === 0}>
              Delete
            </Button>
            <Button onClick={handleCompleteTreatment} color="success" disabled={selectedRows.length === 0}>
              Complete
            </Button>
            <Button onClick={() => setIsOpenReffereal(true)} color="primary">
              Referral
            </Button>
          </div>
        )}
      </div>

      {dataTableData.length > 0 && <div>
        <Charts dataTableData={dataTableData} setDataTableData={setDataTableDataFun} {...props} selectedRows={selectedRows} handlePriorityChange={handlePriorityChange}>
        <div className='d-flex justify-content-between'>
        {dataTableData.length > 0 && (
          <div className='data-table'>
            <table className='table'>
            <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedRows.length !== 0 && selectedRows.length === dataTableData.filter(item => !item.isSubtotal && item.treatmentStatus !== "Complete").length}
                      onChange={handleSelectAllChange}
                      className='treatmentplan-checkbox'
                      />
                  </th>
                  {['Date', 'TTH', 'Pty', 'Code', 'Description', 'Fee', 'Actions'  ].map(header => (
                    <th style={{ textAlign:header ==='Description'?'left': 'center' }}>{header}</th>
                  ))}
                </tr>
              </thead>
              {renderTableData(dataTableData)}
              <tfoot>
                <tr className="text-center" style={{ fontWeight: 'bold' }}>
                  <td colSpan="6">Total:</td>
                  <td colSpan="2" style={{textAlign : 'left'}}>${totalFee.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {/* {dataTableData.length > 0 && (
          <div class='priority-table'>
            <label htmlFor="priority-list-box">Priority</label>
            <Controller
              name="priority"
              control={control}
              render={({ field }) => (
                <ListBox {...field} options={[{ label: 'No Priority', value: null }, ...Array.from({ length: 10 }, (_, i) => ({ label: (i + 1).toString(), value: i + 1 }))]}
                  onChange={e => handlePriorityChange(e.value)}
                  style={{ width: '130px' }}
                  listStyle={{ maxHeight: '580px' }}
                />
              )}
            />
          </div>
        )} */}
      </div>
        </Charts>
      </div> }  
      
      <DeleteRowModal
        openDeleteModal={isDeleteModalOpen}
        deleteSelectedRow={isMultiDelete ? handleMultiDelete : handleDelete}
        closeDeleteModal={() => {
          setIsDeleteModalOpen(false);
          setIsMultiDelete(false);
        }}
      />
      <TreatmentPlanDialog
        visible={dialogVisible}
        onHide={handleCloseDialog}
        selectedRowData={selectedItemForEdit}
        onSave={handleSaveChanges}
      />

     { isOpenReffereal && 
          <RefferealModal
          isOpenReffereal={isOpenReffereal}
          setIsOpenReffereal={setIsOpenReffereal}
          onHide={handleCloseDialog}
          {...props}
          />
      }
      
      {isOpenSignDialog && getSignDialog()}

      <NoteModal visible={noteDialogVisible} onHide={() => setNoteDialogVisible(false)} note={selectedNote} />

     
     {IsOpenAutoNotes && getAutoNoteDialog()}
    
    </div>
  );
};

export default DragAndDropTreatment;