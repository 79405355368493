import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useState, useEffect } from 'react'
import fetchMethodRequest from '../../../config/service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import apiCalls from '../../../config/apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage"
import { InputTextarea } from 'primereact/inputtextarea';
import { faTrash, faEdit, faInfoCircle, faUser, } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Button } from 'reactstrap';
import config from '../../../config/config';
import ConfirmationDialog from './ConfirmationDialog';
function RefferealModal(props) {

  const [refferals, setRefferals] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const patientData = props.getValues('patientId');
  const [patientDetails, setPatientDetails] = useState({});
  const [rowData, setRowData] = useState({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const tableFields = [
    { field: "SNo", header: "SNo", style: { width: '1%' } },
    { field: 'name', header: 'Name', style: { width: '10%' } },
    { field: 'fullAddress', header: 'Full Address', style: { width: '25%' } },
    { field: 'phone', header: 'Phone', style: { width: '5%' } },
    { field: 'email', header: 'Email', style: { width: '10%' } },
    { field: 'notes', header: 'Notes', editable: true, style: { width: '45%', height: '15px' } },
    { field: 'action', header: "Action", body: 'Action', style: { width: '5%' } }
  ]


  useEffect(() => {
    getReffferal();
  }, []);

  useEffect(() => {
    if (localStorage?.PatientData) {
      let patientDetails = JSON.parse(localStorage.PatientData);
      setPatientDetails(patientDetails)
    }
  }, [localStorage.PatientData]);

  const getReffferal = () => {
    let apiUrl = apiCalls.referrals
    let method = "GET"
    return fetchMethodRequest(method, apiUrl)
      .then(async (response) => {
        if (response && response.referrals.length > 0) {

          let referralsWithSNo = response.referrals.map((referral, index) => ({
            ...referral,
            SNo: index + 1,
            notes: ' '
          }));

          setRefferals(referralsWithSNo);
        }
      })

  }

  const onNoteChange = (e, rowData) => {
    const updatedRefferals = refferals.map(referral =>
      referral.SNo === rowData.SNo ? { ...referral, notes: e.target.value } : referral
    );
    setRefferals(updatedRefferals);
  };

  const notesEditor = (options) => {
    return (
      <div>
        <InputTextarea
          placeholder="Enter notes..."
          value={refferals.note}
          onChange={(e) => onNoteChange(e, options.rowData)}
          rows={2}
          cols={30}
          autoResize
          style={{
            minHeight: '20px',
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '4px',
            padding: '10px',
            color: '#495057',
            backgroundColor: '#fff'
          }}
        />
      </div>
    );
  };

  const getRefferalSlipBody = (rowData) => {

    let referrals = rowData;
    let patient = (patientDetails && Object.keys(patientDetails).length > 0) ? patientDetails : patientData;

    return {
      referral: referrals,
      patient: patient,
    };

  }
  const fetchPdf = async (rowData) => {
    if (!rowData.notes || rowData.notes.trim() === '') {
      setIsConfirmOpen(true);
      return;
    }
    proceedWithPdfGeneration(rowData);
  };

  const proceedWithPdfGeneration = async (rowData) => {
    let payLoad = getRefferalSlipBody(rowData)
    try {
      const response = await axios.post(`${config.apiUrl}referrals/generateReferralPdf`, payLoad, {
        responseType: 'blob',
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfBlobUrl);
      setIsOpen(true);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const savePdfInServer = async () => {
    let referrals = rowData;
    let patient = (patientDetails && Object.keys(patientDetails).length > 0) ? patientDetails : patientData;
    let savePdf = "true"
    let payLoad = {
      referral: referrals,
      patient: patient,
      savePdf:savePdf
    }
    let url=`referrals/generateReferralPdf`;

    fetchMethodRequest('POST', url, payLoad)
    .then(async (response) => {
      if (response && response.respMessage) {
        showToasterMessage(response.respMessage, 'success');
      } else if (response && response.errorMessage) {
        showToasterMessage(response.errorMessage, 'error');
      }
    }).catch((err) => {
      return err;
    }).finally(() => {
      setIsOpen(false);
    });
  }

  const handleConfirmDownload = () => {
    setIsConfirmOpen(false);
    proceedWithPdfGeneration(rowData);
  };

  const columnBody = (rowData, col) => {

    if (col.field === "action") {
      return (
        <div className='d-flex justify-content-center'>
          <FontAwesomeIcon onClick={() => { setRowData(rowData); fetchPdf(rowData) }} icon={'upload'} style={{ alignItems: "center" }} />
        </div>
      )
    } else if (col.editable) {
      return notesEditor({ rowData });
    }
    return (
      <div>{rowData[col.field]}</div>
    )

  }

  const getRefferalTable = () => {
    return (<>
      {<div className='form mt-4 mb-2' >
        {true &&
          <DataTable value={refferals} style={{ width: "100%", marginLeft: "" }}>
            {tableFields.map((col) => {
              return (
                <Column field={col.field} header={col.header} body={(rowData) => columnBody(rowData, col)} style={col.style} />
              )
            }
            )}

          </DataTable>}
      </div>}
    </>)
  }

  const getRefferalDilog = () => {

    return (<>

      <Dialog
        header="Referral"
        visible={isOpen}
        headerClassName="modal-header"
        draggable={false}
        style={{ width: '60vw', height: '90vh' }}
        onHide={() => setIsOpen(false)}
        modal
      >
        <div className={`row`} style={{ height: "100%" }}>
          <IFrameContainer pdfUrl={pdfUrl}
            patientDetails={patientDetails}
            patientData={patientData}
            getRefferalSlipBody={getRefferalSlipBody}
            rowData={rowData}
            setIsOpen={setIsOpen}
            savePdfInServer={savePdfInServer}
          />
        </div>
      </Dialog>
    </>)

  }


  return (
    <>
      <Dialog
        header="Referral"
        visible={props.isOpenReffereal}
        headerClassName="modal-header"
        draggable={false}
        style={{ width: '75vw', height: '80vh' }}
        onHide={props.onHide}
        modal
      >
        <div className={`row`}>
          {getRefferalTable()}

        </div>


      </Dialog>

      {isOpen ? getRefferalDilog() : null}
      {isConfirmOpen && (
        <ConfirmationDialog
          openConfirmationModal={isConfirmOpen}
          closeConfirmationModal={() => setIsConfirmOpen(false)}
          confirm={handleConfirmDownload}
          text="send the PDF without notes"
        />
      )}
    </>
  )
}

const IFrameContainer = ({ pdfUrl, getRefferalSlipBody, rowData, setIsOpen,savePdfInServer }) => {
  let apiUrl = "referrals/sendEmailToPatient";
  let method = 'POST'

  let payLoad = getRefferalSlipBody(rowData)

  const getSendEmail = () => {

    return fetchMethodRequest(method, apiUrl, payLoad)
      .then(async (response) => {
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setIsOpen(false);
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
        setIsLoading(false);
      }).catch((err) => {
        return err;
      });
  }

  return (
    <>
      <iframe
        src={pdfUrl}
        style={{ width: '100%', height: '90%', border: 'none' }}
        title="PDF Viewer"
      />
      <div className="container">
      <Button color="primary" onClick={savePdfInServer} className='deleteModalBtn marginRight'>Save</Button>
        <Button color="primary" onClick={getSendEmail} className='deleteModalBtn marginRight'>Email</Button>
      </div>
    </>
  )
}

export default RefferealModal